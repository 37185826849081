import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as admintMKYXMmtvkMeta } from "/workspace/pages/admin.vue?macro=true";
import { default as booking_45success1E0SggrMy8Meta } from "/workspace/pages/booking-success.vue?macro=true";
import { default as bookingyPn9a1zRDQMeta } from "/workspace/pages/booking.vue?macro=true";
import { default as carGvWmg4kED9Meta } from "/workspace/pages/car.vue?macro=true";
import { default as getting_45readyIYA1DgzpnvMeta } from "/workspace/pages/getting-ready.vue?macro=true";
import { default as legalsWpKqUfiw48Meta } from "/workspace/pages/legals.vue?macro=true";
import { default as profilePkbik2W1P0Meta } from "/workspace/pages/profile.vue?macro=true";
import { default as roadtripjxNPksc1V2Meta } from "/workspace/pages/roadtrip.vue?macro=true";
import { default as schedule8R2ap9OlOoMeta } from "/workspace/pages/schedule.vue?macro=true";
import { default as survey3OnGkjLEYhMeta } from "/workspace/pages/survey.vue?macro=true";
import { default as validationLlFkQonmS8Meta } from "/workspace/pages/validation.vue?macro=true";
import { default as waiver1tIglsoNkjMeta } from "/workspace/pages/waiver.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: admintMKYXMmtvkMeta || {},
    component: () => import("/workspace/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "admin___fr",
    path: "/fr/admin",
    meta: admintMKYXMmtvkMeta || {},
    component: () => import("/workspace/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "booking-success___en",
    path: "/en/booking-success",
    meta: booking_45success1E0SggrMy8Meta || {},
    component: () => import("/workspace/pages/booking-success.vue").then(m => m.default || m)
  },
  {
    name: "booking-success___fr",
    path: "/fr/booking-success",
    meta: booking_45success1E0SggrMy8Meta || {},
    component: () => import("/workspace/pages/booking-success.vue").then(m => m.default || m)
  },
  {
    name: "booking___en",
    path: "/en/booking",
    meta: bookingyPn9a1zRDQMeta || {},
    component: () => import("/workspace/pages/booking.vue").then(m => m.default || m)
  },
  {
    name: "booking___fr",
    path: "/fr/booking",
    meta: bookingyPn9a1zRDQMeta || {},
    component: () => import("/workspace/pages/booking.vue").then(m => m.default || m)
  },
  {
    name: "car___en",
    path: "/en/car",
    component: () => import("/workspace/pages/car.vue").then(m => m.default || m)
  },
  {
    name: "car___fr",
    path: "/fr/voiture",
    component: () => import("/workspace/pages/car.vue").then(m => m.default || m)
  },
  {
    name: "getting-ready___en",
    path: "/en/getting-ready",
    component: () => import("/workspace/pages/getting-ready.vue").then(m => m.default || m)
  },
  {
    name: "getting-ready___fr",
    path: "/fr/commencer",
    component: () => import("/workspace/pages/getting-ready.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legals___en",
    path: "/en/legals",
    meta: legalsWpKqUfiw48Meta || {},
    component: () => import("/workspace/pages/legals.vue").then(m => m.default || m)
  },
  {
    name: "legals___fr",
    path: "/fr/legals",
    meta: legalsWpKqUfiw48Meta || {},
    component: () => import("/workspace/pages/legals.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/workspace/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "profile___fr",
    path: "/fr/profil",
    component: () => import("/workspace/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "roadtrip___en",
    path: "/en/roadtrip",
    component: () => import("/workspace/pages/roadtrip.vue").then(m => m.default || m)
  },
  {
    name: "roadtrip___fr",
    path: "/fr/roadtrip",
    component: () => import("/workspace/pages/roadtrip.vue").then(m => m.default || m)
  },
  {
    name: "schedule___en",
    path: "/en/schedule",
    meta: schedule8R2ap9OlOoMeta || {},
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: "schedule___fr",
    path: "/fr/schedule",
    meta: schedule8R2ap9OlOoMeta || {},
    component: () => import("/workspace/pages/schedule.vue").then(m => m.default || m)
  },
  {
    name: "survey___en",
    path: "/en/survey",
    meta: survey3OnGkjLEYhMeta || {},
    component: () => import("/workspace/pages/survey.vue").then(m => m.default || m)
  },
  {
    name: "survey___fr",
    path: "/fr/sondage",
    meta: survey3OnGkjLEYhMeta || {},
    component: () => import("/workspace/pages/survey.vue").then(m => m.default || m)
  },
  {
    name: "validation___en",
    path: "/en/validation",
    meta: validationLlFkQonmS8Meta || {},
    component: () => import("/workspace/pages/validation.vue").then(m => m.default || m)
  },
  {
    name: "validation___fr",
    path: "/fr/validation",
    meta: validationLlFkQonmS8Meta || {},
    component: () => import("/workspace/pages/validation.vue").then(m => m.default || m)
  },
  {
    name: "waiver___en",
    path: "/en/waiver",
    meta: waiver1tIglsoNkjMeta || {},
    component: () => import("/workspace/pages/waiver.vue").then(m => m.default || m)
  },
  {
    name: "waiver___fr",
    path: "/fr/renonciation",
    meta: waiver1tIglsoNkjMeta || {},
    component: () => import("/workspace/pages/waiver.vue").then(m => m.default || m)
  }
]