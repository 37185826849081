
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr"
]

export const localeLoaders = {
  "en": [],
  "fr": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.js?hash=c231e734&config=1" /* webpackChunkName: "__i18n_config_js_c231e734" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.js",
  "locales": [
    "en",
    "fr"
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "signup": {
      "en": "/signup",
      "fr": "/connexion"
    },
    "home": {
      "en": "/home",
      "fr": "/accueil"
    },
    "profile": {
      "en": "/profile",
      "fr": "/profil"
    },
    "getting-ready": {
      "en": "/getting-ready",
      "fr": "/commencer"
    },
    "car": {
      "en": "/car",
      "fr": "/voiture"
    },
    "roadtrip": {
      "en": "/roadtrip",
      "fr": "/roadtrip"
    },
    "survey": {
      "en": "/survey",
      "fr": "/sondage"
    },
    "waiver": {
      "en": "/waiver",
      "fr": "/renonciation"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "iso": "en",
    "code": "en"
  },
  {
    "iso": "fr",
    "code": "fr"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
