import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { defineNuxtRouteMiddleware } from "#app";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollToPlugin);
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path !== from.path && import.meta.client) {
    gsap.to(window, {
      delay: 0.4,
      duration: 0.3,
      scrollTo: 0,
      ease: "power2.inOut",
    });
  }
});
